<template>
  <signal-list-2
    itemName="spot"
    listName="all_spots"
    parentElementClass="all-spots-scroll"
    loadUrl="/spots"
    ref="List"
    @recyclerInstance="setRecycler"
    :noResultTitle="lang.no_open_spots ?? 'No open signals'"
    :noResultDescription="
      lang.take_a_short_break ??
      'Take a short break while we wait for the next opportunity to make profit!.'
    "
    :noResultImage="noResultImage"
  ></signal-list-2>
</template>
  
<script>
import SignalList2 from "@/views/components/cat-3/SignalList2";
import noResultImage from "@/assets/img/no-result-home.png";

export default {
  inject: ["store"],

  data() {
    return {
      recycler: null, //an instance of the infinite scroll recycler for mutations,
      noResultImage,
    };
  },

  components: {
    "signal-list-2": SignalList2,
  },

  computed: {
    compActionTrigger() {
      return this.store.state.compActionTrigger;
    },
  },

  watch: {
    compActionTrigger() {
      let action = this.store.state.compAction;

      switch (action.type) {
        //Remove or add a signal to this list
        case "toggle-watch-spot":
          var signal = action.data.signal;
          var watch = action.data.watch;

          //Update watched signal value if it currently exists in items
          var targetSignal = this.recycler.items.find(
            (item) => item.id == signal.id
          );
          if (targetSignal) {
            targetSignal.watched = watch;
          }

          break;
      }
    },
  },

  methods: {
    init(callback=null) {
      this.$refs.List.init();

      if (callback) {
        callback();
      }
    },

    //Setting the recycler instance emitted from itself
    setRecycler(recycler) {
      this.recycler = recycler;
    },
  },
};
</script>
  
  <style>
</style>