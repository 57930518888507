<template>
  <base-layout-2
    :noPageHeader="false"
    :toolbarClass="'home'"
    :toolbarColor="'primary'"
    :headerClass="'fixed-home'"
    :pageDefaultBackLink="'/home'"
    :pageTitle="lang.spots ?? 'Spots'"
    :contentColor="'primary'"
    :selectLanguage="true"
    @content="setIonContent"
  >
    <!-- Mininghamster -->
    <template v-slot:actions-end>
      <ion-button router-link="/settings" id="spots-settings-btn" v-if="!browser">
        <ion-icon slot="icon-only" :icon="settingsSharp"></ion-icon>
      </ion-button>
    </template>

    <template v-slot:segment>
      <ion-segment
        v-model="segment"
        class="segment"
        @ionChange="segmentChanged($event)"
        ref="segment"
        scrollable
      >
        <ion-segment-button
          :value="'0'"
          layout="icon-start"
          id="segment-button-0"
        >
          <ion-label :class="{ notify: badges.allSpots }">{{
            lang.all ?? "All"
          }}</ion-label>
          <!-- <ion-icon :icon="eye"></ion-icon> -->
        </ion-segment-button>

        <ion-segment-button
          :value="'1'"
          layout="icon-start"
          id="segment-button-1"
        >
          <ion-label>{{ lang.pins ?? "Pins" }}</ion-label>
          <!-- <ion-icon :icon="eye"></ion-icon> -->
        </ion-segment-button>
      </ion-segment>
    </template>

    <template v-slot:ion-content>
      <div
        :class="'page-container' + (minimizeHeader ? ' header-minimized' : '')"
      >
        <ion-slides
          :options="slideOpts"
          @ionSlideDidChange="slideChanged($event)"
          ref="slides"
        >
          <ion-slide class="ion-slide">
            <banner
              type="danger"
              v-if="
                lists['all_spots'].initialized &&
                !notificationPermission &&
                !browser
              "
              :title="lang.enable_notifications ?? 'Enable notifications'"
              :actions="banner_actions.notification_permission"
              :text="
                lang.no_notifications_banner_text ??
                'Notifications turned off, you currently cannot receive new signals and target updates when the app is closed.'
              "
              name="notification_permission_banner"
            ></banner>

            <banner
              v-if="
                lists['all_spots'].initialized &&
                !store.state.banners['notification_permission_banner'] &&
                !browser
              "
              :title="
                lang.maximize_profits_during_good_market ??
                'Maximize profits during good market status'
              "
              :text="
                lang.take_profit_according_to_targets ??
                'Take profit according to targets. Sell 50% at target 1, 30% at target 2, and 20% at target 3. Follow roughly the same pattern with signals with more targets.'
              "
              name="all_spots"
            ></banner>

            <!-- App install call to action banner for web only -->
            <download-banner
              v-if="lists['all_spots'].initialized && browser"
              type="info"
              :title="lang.change ?? 'Profit more on the Uptrade app'"
              :text="
                lang.change ??
                'Receive instant notifications for new signals, buy and sell at the right moment, add signals to favorites, and more when you use our signals via the Uptrade app.'
              "
              name="cta_banner_spots"
            ></download-banner>

            <div class="all-spots-scroll parent-scroll ion-padding">
              <all-spots
                ref="allSpots"
                @interfaces="getInterfaces('allSpots', $event)"
                @updateToggle="newSignalToggle"
                :updateProp="newSignal"
                @initialized="listInitialized('all_spots')"
              ></all-spots>
            </div>
          </ion-slide>

          <ion-slide class="ion-slide">
            <div class="pinned-scroll parent-scroll ion-padding">
              <pinned
                v-if="!browser"
                ref="pinned"
                @interfaces="getInterfaces('pinned', $event)"
              ></pinned>

              <pinned-locked v-else></pinned-locked>
            </div>
          </ion-slide>
        </ion-slides>

        <transition name="fade">
          <ion-fab
            class="home-ion-fab scroll-up"
            vertical="bottom"
            horizontal="end"
            slot="fixed"
            v-if="newSignal"
            v-on:click="softReset"
          >
            <ion-fab-button class="ion-fab-button">
              <ion-icon :icon="arrowUp"></ion-icon>
            </ion-fab-button>
          </ion-fab>
        </transition>
      </div>
    </template>
  </base-layout-2>
</template>

<script>
// import {defineAsyncComponent} from "vue";
import {
  IonButton,
  IonIcon,
  IonFab,
  IonFabButton,
  IonSegment,
  IonSegmentButton,
  IonLabel,
  IonSlides,
  IonSlide,
} from "@ionic/vue";
import { settingsSharp, search, arrowUp } from "ionicons/icons";
import BaseLayout2 from "../layouts/Base2";
import AllSpotsVue from "@/views/components/cat-3/AllSpots.vue";
import Pinned from "../components/cat-3/Pinned.vue";
// import SignalFilterBtn from "@/views/components/cat-3/SignalFilterBtn.vue";
import Banner from "../components/cat-3/Banner.vue";
import { Plugins } from "@capacitor/core";
import PinnedLocked from '../components/cat-3/PinnedLocked.vue';
import DownloadBanner from '../components/cat-3/DownloadBanner.vue';
const { NativeHelper } = Plugins;

export default {
  inject: ["store"],
  data() {
    return {
      settingsSharp,
      search,
      arrowUp,
      created: false,
      newSignal: false,
      newSell: false,
      listActionTrigger: false,
      listActionHandler: null,
      filterModal: null,
      ready: false,
      segment: "0",
      minimizeHeader: false, //Flag to toggle between minimized and maximized header state
      lastScrollTop: 0,
      slideOpts: {
        initialSlide: 0,
        slidesPerView: 1,
        speed: 200,
      },
      lists: {
        all_spots: {
          initialized: false,
        },
      },
      content: null,
      badges: {
        //Flags to toggle the red dot badge in segments for indicating new data
        allSpots: false,
      },

      reloadOnInit: {
        //The associated slide reloads on init() when its value is set to true
        allSpots: false,
        pinned: false,
      },

      banner_actions: {
        notification_permission: [
          {
            text: "Enable",
            handler() {
              NativeHelper.goToNotificationSettings();
            },
          },
        ],
      },
    };
  },
  components: {
    IonButton,
    IonIcon,
    IonFabButton,
    IonFab,
    BaseLayout2,
    IonSegment,
    IonSegmentButton,
    IonLabel,
    "all-spots": AllSpotsVue,
    pinned: Pinned,
    IonSlides,
    IonSlide,
    // SignalFilterBtn,
    Banner,
    PinnedLocked,
    DownloadBanner,
  },

  //Storing interface for methods in child components.
  childInterface: {
    allSpots: {},
    scalps: {},
    leveraged: {},
    holds: {},
  },

  methods: {
    refresh() {
      let self = this;

      //Reload current slide
      self.update();

      //Set all others to reload on init.
      Object.keys(self.reloadOnInit).forEach((item) => {
        if (item != self.currentSegment.ref) {
          self.reloadOnInit[item] = true;
        }
      });
    },

    reactToNewSpot(badges = true, target_update = false) {
      //when 'badges' is set to true, badges appear in the associated segment.
      let self = this;

      //If current segment is all
      if (self.segment == 0) {
        //Update current segment
        self.update();

        //If it's a target update, reload pinned on enter
        if (target_update) {
          self.reloadOnInit.pinned = true;
        }
      } else if (self.segment == 1) {
        //We're in pinned

        if (target_update) {
          self.update(); //Update pinned
        }

        self.reloadOnInit.allSpots = true;

        if (badges) {
          self.badges.allSpots = true;
        }
      }
    },

    setIonContent(content) {
      //Get the page's ion-content element for scroll control
      this.content = content;
    },

    listInitialized(listName) {
      this.lists[listName].initialized = true;
    },

    //Set a child component's interfaces emitted from its own child component
    getInterfaces(slide, interfaces) {
      this.$options.childInterface[slide] = interfaces;
    },

    softReset() {
      //Soft reset whatever slide is in view.
      this.$options.childInterface[this.currentSegment.ref].softReset();
    },

    hardReset() {
      //Hard reset whatever slide is in view.
      this.$options.childInterface[this.currentSegment.ref].hardReset();
    },

    update() {
      //Gets current slide to react to a new item
      this.$options.childInterface[this.currentSegment.ref].update();
    },

    //React to when the value of newSignal is decided to be set true or false from SignalList2 child component
    newSignalToggle(bool) {
      if (bool) {
        this.newSignal = true;
      } else {
        this.newSignal = false;
      }
    },

    init() {
      let self = this;

      //If there is a new signal
      if (self.store.state.newItems.signal) {
        let type = self.store.state.newItems.signal.type;
        self.reactToNewSpot(type);

        //Reset new signal status to null
        self.store.setters.setNewItem("signal", null);

        //Turn off badge in spots icon
        this.store.actions.triggerCompAction("new_spot_seen", null);
      } else if (self.store.state.reloadOnEnter.signal) {
        self.reactToNewSpot();

        //Reset reload on enter status for 'signal' key to false
        self.store.setters.reloadOnEnter("signal", null);

        //Turn off badge in spots icon
        this.store.actions.triggerCompAction("new_spot_seen", null);
      }

      //Wait for next tick when segment will be available in DOM
      self.$nextTick(() => {
        //Initialize whichever segment is visible.
        self.currentSegment.comp.init();

        //If current segment has a new item badge
        if (self.badges[self.currentSegment.ref]) {
          //Remove the badge
          self.badges[self.currentSegment.ref] = false;
        }

        //If current segment has been set to reload on init()
        if (self.reloadOnInit[self.currentSegment.ref]) {
          //Reload it and set the reloadOnInit value to false.
          self.hardReset();
          self.reloadOnInit[self.currentSegment.ref] = false;
        }
      });
    },

    //Segment change event
    async segmentChanged(ev) {
      //Changes slide as well
      let slides = this.$refs.slides.$el;
      await slides.slideTo(ev.detail.value);

      this.segment = ev.detail.value;

      //Reinitialize whatever segment was just changed to.
      this.init();
    },

    slideChanged(ev) {
      ev.target;
      let self = this;

      let slides = this.$refs.slides.$el;
      slides.getActiveIndex().then((selectedIndex) => {
        self.segment = selectedIndex;
        let el = document.getElementById("segment-button-" + self.segment);
        self.scrollIntoView(el);
      });
    },

    scrollIntoView(el) {
      el.scrollIntoView({
        // behavior: "smooth",
        block: "center",
        inline: "center",
      });
    },
  },

  computed: {

    notificationPermission() {
      return this.store.state.notificationPermission;
    },

    compActionTrigger() {
      return this.store.state.compActionTrigger;
    },

    //Get current segment
    currentSegment() {
      let self = this;
      let obj = {};
      switch (self.segment) {
        case "0":
        case 0:
          obj.ref = "allSpots";
          obj.type = "all";
          break;
        case "1":
        case 1:
          obj.ref = "pinned";
          obj.type = "pinned";
          break;
        case "2":
        case 2:
          obj.ref = "scalps";
          obj.type = "scalp";
          break;
        case "3":
        case 3:
          obj.ref = "leveraged";
          obj.type = "leveraged";
          break;
        case "4":
        case 4:
          obj.ref = "holds";
          obj.type = "hold";
          break;
      }

      obj.comp = self.$refs?.[obj.ref];
      return obj;
    },

    settings() {
      return this.store.state.settings;
    },
  },

  watch: {
    compActionTrigger() {
      let action = this.store.state.compAction;
      let self = this;

      switch (action.type) {
        //There is a new spot signal
        case "new_spot":
          self.reactToNewSpot();
          break;
        case "spots_target_update":
          //We're using the reactToNewSpot() method to react to the target update.
          self.reactToNewSpot(false, true);
          break;
      }
    },
  },

  ionViewDidEnter() {
    //If app is not yet ready (Whatever that means)
    if (!this.store.state.appReady) {
      //Let everyone know it is
      this.emitter.emit("appSignedIn");
    }

    //In any case initialize whatever segment is in view.
    this.init();
  },

  ionViewWillLeave() {
    // this.$refs.signalList.$refs.recycler.parentViewWillLeave();
  },

  ionViewDidLeave() {
    // this.ready = false;
  },

  mounted() {
    let self = this;
    self.emitter.on("spots_target_update", async () => {
      self.reactToNewSpot(false, true);
    });
  },

  created() {
    // let deviceData = this.store.state.deviceData;
    // console.log({deviceData});
  },
};
</script>

<style scoped lang="scss">
.page-container {
  transition: margin-top 0.2s, height 0.2s;
  border-top-left-radius: 40px;
  background: #fafafc;
  overflow: auto;
  position: relative;
  @include toolbar-content-size-2;

  ion-slides {
    height: 100%;

    ion-slide.ion-slide {
      flex-direction: column;
    }
  }

  .parent-scroll {
    width: 100%;
    overflow: auto;
    height: 100%;
  }

  &.header-minimized {
    margin-top: 73px;
    height: calc(100% - 73px);
    .parent-scroll {
      padding-top: 76px;
    }

    ion-fab.home-ion-fab {
      top: 100px;
    }
  }
}

ion-fab.home-ion-fab {
  position: fixed;
  left: calc(50% - 28px);
  top: 156px;
  width: fit-content;
  height: fit-content;

  ion-fab-button.ion-fab-button {
    --background: #{lighten($primary-color, 70%)};
  }

  ion-icon {
    color: $primary-color;
  }
}

.no-result {
  width: 100%;
  height: 100%;
  // background: #ccc;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  top: 0px;

  .image {
    width: 50%;
    margin-bottom: 50px;
  }

  p {
    position: relative;
    margin-bottom: 56px;
    margin-top: 10px;
    color: #888;

    &:after {
      content: "";
      width: 100%;
      height: 1px;
      background: #dedede;
      position: absolute;
      bottom: -29px;
      left: 0;
    }
  }

  h4 {
    font-weight: 800;
  }
}

ion-segment.segment {
  width: calc(100% - 40px);
  margin-left: 40px;
  margin-top: -10px;

  ion-segment-button {
    --color: var(--ion-color-secondary-contrast);
    --color-checked: var(--ion-color-secondary);
    text-transform: unset;
    font-size: calc(16px + $extra-font-size);

    ion-label {
      // font-family: "Montserrat-Black";
      transition: font-size 0.2s;

      position: relative;

      &.notify:after {
        position: absolute;
        display: block;
        content: "";
        width: 7px;
        height: 7px;
        border-radius: 50%;
        background: #ffffff;
        top: 0;
        right: -14px;
        background: $danger-color;
      }
    }

    &.segment-button-checked {
      ion-label {
        font-size: calc(21px + $extra-font-size);
        font-weight: 900;
      }
    }
  }
}
</style>
